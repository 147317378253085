var openSalesforceChat = openSalesforceChat || function () {};

(function ($, Drupal) {
  Drupal.behaviors.liveChatServiceDrawer = {
    attach: function (context) {
      var $lcServiceDrawer = $('.js-lc-service-drawer-v2', context);
      var $pageOverlay = $('.js-lc-service-drawer-overlay-v2', context);
      var $liveChat = $('.js-live-chat', context);
      var $beautyAdvisorBtn = $('.js-lc-beauty-advisor-btn', $lcServiceDrawer);
      var $orderStatusBtn = $('.js-lc-order-status-btn', context);

      function closeLcServiceDrawer() {
        $lcServiceDrawer.slideUp();
        $pageOverlay.slideUp();
      }
      function openLcServiceDrawer() {
        $lcServiceDrawer.slideDown();
        $pageOverlay.slideDown();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat offered',
            event_label: 'click'
          });
        }
      }

      $liveChat.once().on('click', function (event) {
        event.stopPropagation();
        event.preventDefault();

        if ($lcServiceDrawer.is(':visible')) {
          closeLcServiceDrawer();

          return;
        }

        openLcServiceDrawer();
      });

      $pageOverlay.once().on('click', function (event) {
        event.stopPropagation();
        closeLcServiceDrawer();
      });

      $('html')
        .once()
        .on('click', function (event) {
          if (
            !$(event.target).hasClass('js-lc-service-drawer') &&
            !$(event.target).hasClass('js-live-chat')
          ) {
            closeLcServiceDrawer();
          }
        });
      $beautyAdvisorBtn.once().on('click', function (event) {
        var $lpmTrigger = $('.LPMcontainer img.LPMimage');

        event.preventDefault();
        closeLcServiceDrawer();
        if ($lpmTrigger.length > 0) {
          $lpmTrigger.trigger('click');
          if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
            site.track.evtLink({
              event_name: 'live chat interaction',
              event_category: 'live chat interaction',
              event_action: 'chat type selection',
              event_label: 'Beauty Advisor'
            });
          }
        }
      });
      $orderStatusBtn.once().on('click', function (event) {
        event.preventDefault();
        closeLcServiceDrawer();
        openSalesforceChat();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat type selection',
            event_label: 'Customer Service'
          });
        }
      });
    }
  };
})(jQuery, Drupal);
